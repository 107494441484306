
import {
	computed,
	defineComponent,
	getCurrentInstance,
	onMounted,
	reactive,
	readonly,
	ref
} from 'vue'
import { useStore } from '../store'
import { useRouter } from 'vue-router'
import AdminLayout from '../global-components/layouts/AdminLayout.vue'
import IqProApi from '../apis/IqProApi'
import { AdminApiRequest } from '../types/admin-simple-pay'
import * as Sentry from '@sentry/vue'
import mixpanel from 'mixpanel-browser'
import * as mp from '../apis/mixpanel'

export default defineComponent({
	name: 'AdminSimplePayCreateView',
	components: {
		AdminLayout
	},
	setup() {
		const store = useStore()
		const router = useRouter()

		const createdPageID = ref(null)
		const showError = ref(false)
		const simplePayCreateForm = ref(null)
		const distinctId = ref('')
		const app = getCurrentInstance()

		const formData = reactive({
			merchantName: '',
			apiKey: '',
			fpMID: '',
			includeCardForm: true,
			includeAchForm: false,
			cardProcessorId: '',
			achProcessorId: '',
			secCode: 'web',
			basysMID: '',
			sandboxPage: false
		})

		const formValidationRules = readonly({
			merchantName: [
				{
					required: true,
					message: 'Merchant Name is required',
					trigger: 'blur'
				}
			],
			apiKey: [
				{
					required: true,
					pattern: /^api_\w+$/,
					message: 'A valid Secret API Key is required',
					trigger: 'blur'
				}
			],
			fpMID: [
				{
					required: true,
					pattern: /^(?!\s+)\w+$/,
					message: 'A valid FP Merchant ID is required',
					trigger: 'blur'
				}
			],
			basysMID: [
				{
					required: false,
					pattern: /^(?!\s+)\w+$/,
					message: 'A valid Basys MID is required',
					trigger: 'blur'
				}
			]
		})

		const submitEnabled = computed((): boolean => {
			return formData.includeCardForm || formData.includeAchForm
		})

		onMounted(() => {
			store.dispatch('hideLoadingScreen')
			distinctId.value =
				app.appContext.config.globalProperties.$distinct_id
			mixpanel.identify(distinctId.value)
		})

		function goBack() {
			store.dispatch('showLoadingScreen')
			router.push({ name: 'admin' })
		}
		async function submitForm() {
			store.dispatch('showLoadingScreen')
			let isFormValid = false

			simplePayCreateForm.value.validate((isValid: boolean) => {
				isFormValid = isValid
			})

			if (!isFormValid) {
				console.log('failed validation')
				return false
			}

			let basysMID =
				formData.basysMID && formData.basysMID.trim().length
					? formData.basysMID.trim()
					: null
			let cardProcessorId =
				formData.cardProcessorId &&
				formData.cardProcessorId.trim().length
					? formData.cardProcessorId.trim()
					: null
			let achProcessorId =
				formData.achProcessorId && formData.achProcessorId.trim().length
					? formData.achProcessorId.trim()
					: null
			let apiRequest: AdminApiRequest = {
				secretApiKey: formData.apiKey.trim(),
				merchantId: formData.fpMID.trim(),
				basysMID: basysMID,
				simplePaySettings: {
					merchantName: formData.merchantName.trim(),
					logo: null,
					cardProcessorId: cardProcessorId,
					achProcessorId: achProcessorId,
					isActive: true,
					hasCardForm: formData.includeCardForm,
					hasAchForm: formData.includeAchForm,
					achProcessing: formData.secCode,
					acceptedCards: [
						'Visa',
						'MasterCard',
						'Discover',
						'American Express'
					],
					primaryColor: '#0d304c',
					secondaryColor: '#0d304c',
					enlargeLogo: false,
					bodyFont: 'Avenir',
					headerFont: 'Roboto Slab',
					bodyFontColor: '#6d6c6b',
					headerFontColor: '#6d6c6b',
					backgroundColor: '#21669e',
					formBackgroundColor: '#fff',
					headerText: null,
					hasBanner: false,
					bannerType: 'warning',
					bannerMessage: null,
					successMessage: null,
					declineMessage: null,
					successOverrideUrl: null,
					backToHomeOverrideUrl: null,
					webHookUrl: null,
					cardFields: {
						companyIsIncluded: true,
						companyIsRequired: false,
						companyLabelText: 'Company',
						addressIsIncluded: true,
						addressIsRequired: true,
						additionalInfoHeaderText: 'Additional Information',
						emailIsIncluded: true,
						emailIsRequired: true,
						phoneIsIncluded: true,
						phoneIsRequired: true,
						orderIdIsIncluded: true,
						orderIdIsRequired: false,
						orderIdLabelText: 'Order ID',
						poNumberIsIncluded: true,
						poNumberIsRequired: false,
						poNumberLabelText: 'PO Number',
						noteIsIncluded: true
					},
					achFields: {
						companyIsIncluded: true,
						companyIsRequired: false,
						companyLabelText: 'Company',
						noteIsIncluded: true,
						additionalInfoHeaderText: 'Additional Information',
						orderIdIsIncluded: true,
						orderIdIsRequired: false,
						orderIdLabelText: 'Order ID',
						poNumberIsIncluded: true,
						poNumberIsRequired: false,
						poNumberLabelText: 'PO Number',
						driversLicenseIsIncluded: false,
						dobSSNIsIncluded: false
					}
				}
			}

			const resp = await IqProApi.createSimplePay(apiRequest)

			if (
				resp &&
				resp.data &&
				resp.data.length &&
				resp.statusText.toLowerCase() === 'created'
			) {
				createdPageID.value = resp.data
				simplePayCreateForm.value.resetFields()
				store.dispatch('getSimplePayData')
				router.push({
					name: 'adminEdit',
					params: { id: createdPageID.value }
				})
				mp.createdPagePeopleCount()
			} else {
				console.log('createSimplePay: failed')
				Sentry.captureMessage('createSimplePay: failed')

				Sentry.withScope(function (scope) {
					scope.setTag('apiKey', formData.apiKey)
					scope.setTag('merchantName', formData.merchantName)
					scope.setTag('merchantId', apiRequest.merchantId)
					Sentry.captureMessage('createSimplePay: failed')
				})

				showError.value = true
				store.dispatch('hideLoadingScreen')
			}
			// store.dispatch('hideLoadingScreen')
		}
		function clearError() {
			showError.value = false
		}
		function clearBasysMID() {
			formData.basysMID = ''
		}

		return {
			simplePayCreateForm,
			formData,
			formValidationRules,
			showError,
			clearError,
			clearBasysMID,
			goBack,
			submitEnabled,
			submitForm
		}
	}
})
